<template>
    <div class="items-center hidden lg:flex">
        <div
            class="hover:text-indigo-500 inline-flex items-center font-bold cursor-pointer ml-5 p-2 z-30"
            :class="{'bg-white border-2 border-black pl-1.5': menuOpened}"
            @click="menuOpened = !menuOpened"
        >
            <Cross v-if="menuOpened" width="25"/>
            <Group v-else width="25"/>
            <span class="mt-0.5 ml-1">Каталог</span>
        </div>

        <div v-if="menuOpened" class="absolute z-30 top-20 left-30 transform mt-2">
            <div class="bg-white border-2 border-black shadow-sm">
                <ul class="font-bold w-64">
                    <li v-for="menuItem in menu">
                        <div @mouseover="hoveredItem(menuItem)">
                            <div
                                v-if="menuItem.options"
                                class="p-4 flex justify-between items-center cursor-pointer hover:text-indigo-500 hover:bg-gray-100"
                                :class="{'text-indigo-500 bg-gray-100': menuItem.options && hoveredMenuItem === menuItem}"
                            >
                                                    <span class="inline-flex">
                                                        <component :is="menuIcons[menuItem.slug]" class="h-5 mr-3"/>
                                                        <span class="mr-2">{{ menuItem.title }}</span>
                                                    </span>
                                <Chevron width="10" height="10"/>
                            </div>
                            <Link
                                v-else
                                class="p-4 flex justify-between items-center cursor-pointer hover:text-indigo-500 hover:bg-gray-100"
                                :href="route('categories.show', {category: menuItem})"
                            >
                                                    <span class="inline-flex">
                                                        <component :is="menuIcons[menuItem.slug]" class="h-5 mr-3"/>
                                                        <span class="mr-2">{{ menuItem.title }}</span>
                                                    </span>
                            </Link>
                        </div>

                        <div
                            v-if="menuItem.options"
                            class="absolute top-0 left-64 w-56 min-h-full border-2 border-black shadow-sm bg-gray-50"
                            :class="hoveredMenuItem === menuItem ? 'block' : 'hidden'"
                        >
                            <div>
                                <Link
                                    v-if="menuItem.all_title"
                                    class="block p-4 text-sm text-black hover:text-indigo-500 hover:bg-gray-100"
                                    :href="route('categories.show', {category: menuItem})"
                                    v-text="menuItem.all_title"
                                    @mouseover="subItemHovered(null)"
                                />

                                <div v-for="menuSubItem in menuItem.options" @mouseover="subItemHovered(menuSubItem)">
                                    <Link
                                        v-if="menuSubItem.slug"
                                        class="block p-4 text-sm text-black hover:text-indigo-500 hover:bg-gray-100"
                                        :href="route('categories.show', {category: menuSubItem})"
                                        v-text="menuSubItem.title"
                                    />
                                    <div
                                        v-else
                                        :class="{'bg-gray-100 text-indigo-500': hoveredSubItem === menuSubItem}"
                                        class="p-4 text-sm hover:bg-gray-100 cursor-pointer hover:text-indigo-500"
                                    >
                                        <span class="w-full inline-flex justify-between items-center">
                                            {{ menuSubItem.title }}
                                            <Chevron width="10" height="10"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="hoveredSubItem?.options" class="absolute top-0 left-[29.7rem] w-56 min-h-full border-2 border-black shadow-sm bg-gray-50">
                            <Link
                                v-for="menuSubSubItem in hoveredSubItem.options"
                                class="block p-4 text-sm text-black hover:text-indigo-500 hover:bg-gray-100"
                                :href="route('categories.show', {category: menuSubSubItem})"
                                v-text="menuSubSubItem.title"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div
            v-if="menuOpened"
            class="fixed inset-0 bg-gray-700/40 mt-20"
            @click="menuOpened = false"
        ></div>
    </div>
</template>

<script>
import Cross from "@/Components/Svg/Cross.vue";
import Group from "@/Components/Svg/Group.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import {Link} from "@inertiajs/vue3";
import Zap from "@/Components/Svg/Zap.vue";
import Cup from "@/Components/Svg/Cup.vue";
import Camera from "@/Components/Svg/Camera.vue";
import Drink from "@/Components/Svg/Drink.vue";

export default {
    components: {Link, Chevron, Group, Cross, Zap, Cup, Camera, Drink},
    props: {
        menu: Array
    },
    data() {
        return {
            menuIcons: {
                coffee: 'Cup',
                drips: 'Zap',
                equipment: 'Camera',
                drinks: 'Drink'
            },
            menuOpened: false,
            hoveredMenuItem: null,
            hoveredSubItem: null,
        }
    },
    methods: {
        hoveredItem(hoveredMenuItem) {
            this.hoveredMenuItem = hoveredMenuItem
            this.hoveredSubItem = null
        },
        subItemHovered(hoveredSubItem) {
            this.hoveredSubItem = hoveredSubItem
        }
    }
}
</script>
