<template>
    <div>
        <div class="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-900/80 z-50"
             :class="{hidden: !opened}"
             @click="toggleSidebar"></div>
        <div
            class="fixed top-0 bottom-0 w-full sm:max-w-sm z-50 transition-all duration-300 bg-white"
            :class="[
                opened ? '' : `${position === 'left' ? '-' : ''}translate-x-full`,
                `${position}-0`
            ]"
        >
            <div v-if="loading" class="absolute top-0 z-[100] bg-white sm:max-w-sm w-full h-full right-0 opacity-90 flex justify-center items-center">
                <Loader class="h-10 w-10 text-indigo-600"/>
            </div>

            <div class="flex flex-col h-full">
                <div class="w-full border-b z-50">
                    <div class="flex justify-between items-center p-5">
                        <p v-if="header" class="text-lg font-heading">{{ header }}</p>

                        <slot v-else name="header"/>

                        <Cross
                            width="25"
                            class="cursor-pointer hover:text-indigo-500"
                            @click="toggleSidebar"
                        />
                    </div>
                </div>

                <div class="overflow-y-auto mb-auto" :class="`p-${contentPadding}`" ref="sidebarView">
                    <slot/>
                </div>

                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>

<script>
import { lock, unlock } from 'tua-body-scroll-lock'
import Loader from "@/Components/Svg/Loader.vue";
import Cross from "@/Components/Svg/Cross.vue";

export default {
    components: {Cross, Loader},
    props: {
        header: String,
        modelValue: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left'
        },
        loading: {
            default: false
        },
        contentPadding: {
            default: 5
        },
        lockScroll: {
            default: true
        }
    },
    data () {
        return {
            opened: this.modelValue,
        }
    },
    watch: {
        modelValue: function () {
            this.opened = this.modelValue

            if (this.opened) {
                this.$emit('opened')
            }

            if (this.lockScroll) {
                this.opened
                    ? lock(this.$refs.sidebarView, { overflowType: 'clip' })
                    : unlock(this.$refs.sidebarView)
            }
        }
    },
    methods: {
        toggleSidebar () {
            this.opened = !this.opened

            this.$emit('update:modelValue', this.opened)
        }
    }
}
</script>
