<template>
    <div class="mx-auto" :class="{'px-4': !noPadding}">
        <div class="max-w-6xl mx-auto">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        noPadding: {
            default: false
        }
    }
}
</script>
