<template>
    <Sidebar
        position="right"
        header="Кошик"
        :loading="loading"
        @opened="sidebarOpened"
    >
        <DeletedProductsFromCart :deleted-products="cart.deletedProducts"/>

        <div v-if="cart.groups?.length">
            <template v-for="company in cart.groups" :key="company.id">
                <div>
                    <span class="font-bold leading-none">
                        Замовлення у {{ company.title }}
                    </span>

                    <hr class="my-5">
                </div>
                <div class="mt-4" v-for="orderItem in company.products">
                    <div class="flex items-center">
                        <div class="flex-none w-16">
                            <Link :href="route('products.show', [orderItem.product])">
                                <ResponsiveImage
                                    :media-item="orderItem.product.image"
                                    class="img-fluid w-full"
                                />
                            </Link>
                        </div>
                        <div class="w-full ml-2 p-2">
                            <div class="flex justify-between">
                                <div>
                                    <Link :href="route('products.show', [orderItem.product])">
                                        <h4 class="text-base font-bold leading-none hover:text-indigo-500">
                                            {{ orderItem.product.title }}</h4>
                                    </Link>
                                    <span class="text-sm font-bold"
                                          v-html="modificationsString(orderItem.modifications)"/>
                                </div>
                                <div @click="deleteItem(orderItem)" class="cursor-pointer ml-2">
                                    <Cross class="text-red-400 h-5"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 flex items-center min-[400px]:ml-20">
                        <NumberInput
                            class="input-small"
                            :modelValue="orderItem.quantity"
                            controls
                            inline
                            center
                            :min="1"
                            :max="100"
                            @update:model-value="onUpdate($event, orderItem)"
                        />
                        <div class="ml-5 font-bold text-sm">
                            <p>
                                {{ orderItem.price.formatted || orderItem.product.price.formatted }} <span
                                class="text-gray-400">за 1 шт.</span>
                            </p>
                            <p>
                                <span class="text-gray-400">Всього:</span>
                                {{ orderItem.subtotal.formatted }}
                            </p>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
            </template>
        </div>

        <div v-else class="flex justify-center items-center m-auto">
            <div class="text-center mx-5">
                <h2 class="font-bold text-3xl mb-7">Кошик порожній</h2>

                <p>Але це легко виправити 😉</p>
            </div>
        </div>

        <template v-if="cart.groups?.length" #footer>
            <div class="p-5 bg-green-500">
                <div class="flex pb-6 items-center justify-between">
                    <span class="font-bold">Товарів на суму</span>
                    <span class="font-black">{{ cart.subtotal.formatted }}</span>
                </div>

                <Link :href="route('orders.create')">
                    <PrimaryButton>Оформити замовлення</PrimaryButton>
                </Link>
            </div>
        </template>
    </Sidebar>
</template>

<script>
import Sidebar from "@/Components/Sidebar.vue";
import {Link, router, useForm} from "@inertiajs/vue3";
import ResponsiveImage from "@/Components/ResponsiveImage.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import NumberInput from "@/Components/Forms/NumberInput.vue";
import modificationsFormat from "@/mixins/modificationsFormat";
import Loader from "@/Components/Svg/Loader.vue";
import Alert from "@/Components/Alert.vue";
import DeletedProductsFromCart from "@/Components/Alerts/DeletedProductsFromCart.vue";
import Cross from "@/Components/Svg/Cross.vue";
import {ecommerce, cartEcommerceEvent} from "@/Helpers/zaraz";

export default {
    mixins: [modificationsFormat],
    components: {
        Cross,
        DeletedProductsFromCart,
        Alert, Loader, NumberInput, Link, PrimaryButton, ResponsiveImage, Sidebar
    },
    props: {
        cart: Object
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        sidebarOpened() {
            this.loading = true

            router.reload({
                preserveScroll: true,
                preserveState: true,
                only: ['cart'],
                onSuccess: (data) => cartEcommerceEvent('Cart Viewed', data.props.cart.subtotal, data.props.cart.groups),
                onFinish: () => this.loading = false
            })
        },
        onUpdate(quantity, orderItem) {
            if (orderItem.quantity !== quantity) {
                this.loading = true

                if (quantity > orderItem.quantity) {
                    const addingQuantity = quantity - orderItem.quantity

                    ecommerce('Product Added', orderItem.product, {
                        quantity: addingQuantity
                    })
                } else {
                    this.removeFromCartEvent(orderItem, orderItem.quantity - quantity)
                }

                useForm({
                    id: orderItem.id,
                    quantity
                }).put(this.route('cart.update'), {
                    preserveScroll: true,
                    only: ['cartProductsCount', 'cart'],
                    onFinish: () => this.loading = false
                })
            }
        },
        deleteItem(orderItem) {
            this.loading = true

            this.removeFromCartEvent(orderItem, orderItem.quantity)

            useForm({})
                .delete(this.route('cart.destroy', orderItem.id), {
                    preserveScroll: true,
                    only: ['cartProductsCount', 'cart'],
                    onFinish: () => this.loading = false
                })
        },
        removeFromCartEvent(orderItem, quantity) {
            ecommerce('Product Removed', orderItem.product, {
                quantity,
                price: orderItem.price.amount / 100,
                currency: orderItem.price.currency
            })
        }
    }
}
</script>
