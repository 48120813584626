<template>
    <Sidebar content-padding="0">
        <template #header>
            <ApplicationLogo class="max-h-10 w-24 sm:w-40"/>
        </template>

        <div class="w-full overflow-hidden flex flex-nowrap">
            <div class="flex-none w-full flex flex-col transition-all duration-300" :class="{'-translate-x-full h-0': isSubMenu}">
                <h3 class="font-heading p-4">Каталог товарів</h3>

                <div v-for="subItem in menu">
                    <div
                        v-if="subItem.options"
                        class="menu-item p-5 flex justify-between items-center"
                        @click="openSubCatalog(subItem)"
                    >
                        <p class="inline-flex items-center">
                            <component :is="menuIcons[subItem.slug]" class="h-5 mr-3"/>
                            <span class="mt-0.5">{{ subItem.title }}</span>
                        </p>
                        <Chevron class="w-4"/>
                    </div>
                    <Link v-else :href="route('categories.show', {category: subItem})">
                        <div class="menu-item p-5 flex items-center">
                            <component :is="menuIcons[subItem.slug]" class="h-5 mr-3"/>
                            <span class="mt-0.5">{{ subItem.title }}</span>
                        </div>
                    </Link>
                </div>

                <div>
                    <Link :href="route('map')"
                          class="inline-flex items-center hover:text-indigo-500 p-5 border-t-2 border-b-2 w-full">
                        <Map width="25"/>
                        <span class="ml-3 font-bold">Мапа закладів</span>
                    </Link>

                    <LinksList class="p-4 border-b-2" title="Інфо" :links="infoPages"/>

                    <LinksList class="p-4 border-b-2" title="Продавцям" :links="sellerPages"/>

                    <div class="p-4">
                        <h3 class="font-heading mb-6">Контакти</h3>

                        <span class="inline-block text-sm font-bold mb-3 pl-1">
                            Звʼязатись з нами: <a class="hover:underline"
                                                  href="tel:+380665935126">+380 (66) 593-51-26</a>
                        </span>
                        <span class="inline-block text-sm font-bold pl-1">
                            Email: <a class="hover:underline" href="mailto:cupcult.official@gmail.com">cupcult.official@gmail.com</a>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex-none w-full flex flex-col transition-all duration-300" :class="{'-translate-x-full': isSubMenu}">
                <div v-if="isSubMenu">
                    <div class="menu-item p-3 flex mb-3 border-b-2" @click="showAllCategories">
                        <Chevron class="w-4 rotate-180 mr-4"/>
                        <span class="mt-0.5">Усі категорії</span>
                    </div>
                    <Link
                        v-if="menuItem.all_title"
                        class="block px-5 py-2 hover:text-indigo-500 text-lg font-black"
                        :href="route('categories.show', {category: menuItem})"
                        v-text="menuItem.all_title"
                    />
                    <div v-for="subItem in menuItem.options">
                        <Link
                            v-if="subItem.slug"
                            class="block px-5 py-2 hover:text-indigo-500 text-lg font-black"
                            :href="route('categories.show', {category: subItem})"
                            v-text="subItem.title"
                        />
                        <span
                            v-else
                            class="block px-5 py-2 text-lg font-black cursor-default text-gray-400"
                            v-text="subItem.title"
                        />

                        <Link
                            v-for="subMenuItem in subItem.options"
                            class="block pl-7 py-1 hover:text-indigo-500 font-black"
                            :href="route('categories.show', {category: subMenuItem})"
                            v-text="subMenuItem.title"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
</template>

<script>
import LinksList from "@/Layouts/Partials/LinksList.vue";
import {Link} from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import Chevron from "@/Components/Svg/Chevron.vue";
import Sidebar from "@/Components/Sidebar.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import Group from "@/Components/Svg/Group.vue";
import Map from "@/Components/Svg/Map.vue";
import Cup from "@/Components/Svg/Cup.vue";
import Zap from "@/Components/Svg/Zap.vue";
import Camera from "@/Components/Svg/Camera.vue";
import Drink from "@/Components/Svg/Drink.vue";

export default {
    components: {Map, Group, PrimaryButton, Sidebar, Chevron, ApplicationLogo, Link, LinksList, Cup, Zap, Camera, Drink},
    props: {
        infoPages: Array,
        sellerPages: Array,
        menu: Array,
    },
    data() {
        return {
            menuIcons: {
                coffee: 'Cup',
                drips: 'Zap',
                equipment: 'Camera',
                drinks: 'Drink'
            },
            isSubMenu: false,
            menuItem: null
        }
    },
    methods: {
        openSubCatalog(menuItem) {
            this.menuItem = menuItem
            this.isSubMenu = true
        },
        showAllCategories() {
            this.isSubMenu = false
        }
    }
}
</script>

<style scoped>
@reference "../../../css/app.css";

.menu-item {
    @apply font-black hover:bg-gray-100 cursor-pointer hover:text-indigo-500;
}
</style>
